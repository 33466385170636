.MuiTablePagination-toolbar {
  padding: 1px;
  min-height: 1px !important;
}

.animacionAlerta {
  animation-name: example;
  animation-duration: 1s;
}

@keyframes example {
  from {
    top: -100px;
  }
  to {
    top: 5rem;
  }
}
